import React from "react";
import Section from "react-bulma-components/lib/components/section";
import Columns from "react-bulma-components/lib/components/columns";
import Container from "react-bulma-components/lib/components/container";
import classNames from "classnames/bind";
import styles from "./FeaturedPress.module.scss";
import { renderText } from "utils/renderHelpers";
import { renderFluidImage } from "utils/imageHelpers";
const cx = classNames.bind(styles);

export default ({ primary, items }) => {
  const { heading } = primary;
  return (
    <Section className={`grey ${cx({ section: true, grey: true })}`}>
      <Container>
        <Columns mobile multiline>
          <Columns.Column mobile={{ size: 12 }} tablet={{ size: 12 }}>
            {renderText(heading, "h3")}
          </Columns.Column>
          {items.map(item => {
            let story =
              item.stories && item.stories.document
                ? item.stories.document[0]
                : null;
            if (!story) {
              return null;
            }
            return (
              <Columns.Column
                className={styles.item}
                key={story.id}
                mobile={{ size: 12 }}
                tablet={{ size: 4 }}
              >
                <figure className={styles.featureImage}>
                  {renderFluidImage(story.data.feature_image)}
                </figure>
                {story.data.publication_logo &&
                  story.data.publication_logo.url && (
                    <figure className={styles.publicationLogo}>
                      {renderFluidImage(story.data.publication_logo)}
                    </figure>
                  )}
                {renderText(story.data.heading, "h2")}
                {story.data.url &&
                  story.data.url.length > 0 && (
                    <a
                      href={story.data.url}
                      rel="noopener noreferrer"
                      target="_blank"
                      title={story.data.heading.text}
                    >
                      Read more
                    </a>
                  )}
              </Columns.Column>
            );
          })}
        </Columns>
      </Container>
    </Section>
  );
};
