import React from "react";
import Section from "react-bulma-components/lib/components/section";
import Columns from "react-bulma-components/lib/components/columns";
import Container from "react-bulma-components/lib/components/container";
import classNames from "classnames/bind";
import styles from "./PressList.module.scss";
import { renderText } from "utils/renderHelpers";
import { format } from "date-fns";
const cx = classNames.bind(styles);

const formatDate = dateStr => {
  let d = new Date(dateStr);
  return format(d, "MMMM D, YYYY");
};
export default ({ primary, items }) => {
  const { heading } = primary;
  return (
    <Section className={`white ${cx({ section: true, white: true })}`}>
      <Container>
        <Columns mobile multiline>
          <Columns.Column mobile={{ size: 12 }} tablet={{ size: 12 }}>
            {renderText(heading, "h3")}
          </Columns.Column>
          {items.map(item => {
            return (
              <Columns.Column
                className={styles.item}
                key={item.id}
                mobile={{ size: 12 }}
                tablet={{ size: 12 }}
              >
                <a
                  href={item.data.url}
                  rel="noopener noreferrer"
                  target="_blank"
                  title={item.data.heading.text}
                >
                  <Columns mobile multiline>
                    <Columns.Column mobile={{ size: 3 }} tablet={{ size: 2 }}>
                      <h5>{item.data.publication}</h5>
                      <p>{formatDate(item.data.date)}</p>
                    </Columns.Column>
                    <Columns.Column mobile={{ size: 8 }} tablet={{ size: 8 }}>
                      {renderText(item.data.heading, "h2")}
                    </Columns.Column>
                    <Columns.Column
                      mobile={{ size: 1 }}
                      tablet={{ size: 2 }}
                      className={styles.last}
                    >
                      <span>Read more</span>
                    </Columns.Column>
                  </Columns>
                </a>
              </Columns.Column>
            );
          })}
        </Columns>
      </Container>
    </Section>
  );
};
