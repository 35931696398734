import React, { Component } from "react";
import Helmet from "react-helmet";
// Slices
import Hero from "slices/Hero";
import FeaturedPress from "slices/FeaturedPress";
import PressList from "slices/PressList";
import FullWidthCallouts from "slices/FullWidthCallouts";
import Footer from "components/Footer";
// graphql
import { graphql } from "gatsby";
import { sliceTypeMatches } from "utils/slices";

class PressPage extends Component {
  static getPageMeta(globals, page, location) {
    let title = page.seo_title || page.title;
    let description = page.seo_description || globals.seo_description;
    let img_alt = page.seo_image_alt || globals.seo_image_alt || page.title;

    let fb_image = page.seo_image
      ? page.seo_image.facebook
      : globals.seo_image.facebook;

    let twitter_image = page.seo_image
      ? page.seo_image.twitter
      : globals.seo_image.twitter;

    let pageTitle = `${title.text} | Writers' Trust of Canada`;
    let meta = [
      { property: `og:site_name`, content: pageTitle },
      { name: `title`, content: title.text },
      { property: `og:title`, content: title.text },
      { name: `description`, content: description.text },
      { property: `og:description`, content: description.text },
      { property: `og:image`, content: fb_image.url },
      {
        property: `og:image:width`,
        content: fb_image.dimensions ? fb_image.dimensions.width : null,
      },
      {
        property: `og:image:height`,
        content: fb_image.dimensions ? fb_image.dimensions.height : null,
      },
      { property: `og:image:alt`, content: img_alt.text },
      {
        property: `og:url`,
        content: `https://www.writerstrust.com${location.pathname}`,
      },
      {
        name: `twitter:card`,
        content: `summary_large_image`,
      },
      {
        name: `twitter:image`,
        content: twitter_image.url,
      },
    ];
    return meta.filter((m) => m.content);
  }

  renderSlice = (slice, nextSlice) => {
    if (!slice) {
      return null;
    }
    let result = null;
    let Cmp = null;
    let extras = {};
    const slice_type = slice.slice_type;
    switch (true) {
      case sliceTypeMatches(slice_type, "hero"):
        //extras.backLink = this.props.data.page.data.child_of;
        Cmp = Hero;
        break;
      case sliceTypeMatches(slice_type, "full_width_callout_list"):
        let items = slice.items;
        if (items.length && !items[0].link) {
          items = slice.items
            .map((item) => {
              return item && item.callout && item.callout.document
                ? item.callout.document[0].data
                : null;
            })
            .filter(Boolean);
        }
        slice.items = items;
        slice.primary = { ...slice.primary, theme: `grey` };
        Cmp = FullWidthCallouts;
        break;
      case sliceTypeMatches(slice_type, "featured"):
        Cmp = FeaturedPress;
        break;
      case sliceTypeMatches(slice_type, "press_list"):
        Cmp = PressList;
        break;
      default:
        return null;
    }

    if (!Cmp && !result) {
      return null;
    }
    if (!Cmp && result) {
      return result;
    }
    return (
      <Cmp
        {...slice}
        key={slice.id}
        slug={this.props.data.page.slug}
        awardType={this.props.data.page.data.award_type}
        {...extras}
      />
    );
  };

  constructor(props) {
    super(props);
    this.body = Array.from(props.data.page.data.body);
    let idx = 1;
    let listings = props.data.listings.edges.map((edge) => edge.node);
    const listing = {
      id: "presslist",
      slice_type: "press_list",
      primary: { heading: { text: "Most Recent Press" } },
      items: listings,
      theme: "white",
    };
    for (let i = 0; i < this.body.length; i++) {
      if (sliceTypeMatches(this.body[i].slice_type, "featured")) {
        idx = i + 1;
        break;
      }
    }
    this.body.splice(idx, 0, listing);
  }

  shouldComponentUpdate(props) {
    if (props.location.pathname.indexOf(props.data.page.slug) === -1) {
      return false;
    }
    return true;
  }

  render() {
    const { page } = this.props.data;
    return (
      <React.Fragment>
        <Helmet
          bodyAttributes={{
            class: "page",
          }}
          title={`${page.data.title.text} | Writers' Trust of Canada`}
          meta={PressPage.getPageMeta(
            this.props.data.globals.data,
            this.props.data.page.data,
            this.props.location
          )}
        />
        {this.body &&
          this.body.length > 0 &&
          this.body.map((slice, idx) => {
            return this.renderSlice(slice, this.body[idx + 1]);
          })}
        <Footer data={this.props.pageContext.footerData} theme={`black`} />
      </React.Fragment>
    );
  }
}

export const query = graphql`
  query {
    globals: prismicSiteGlobals {
      data {
        seo_description {
          text
        }
        twitter_handle {
          text
        }
        facebook_app_id {
          text
        }
        seo_image {
          twitter {
            url
            dimensions {
              width
              height
            }
          }
          facebook {
            url
            dimensions {
              width
              height
            }
          }
        }
      }
    }
    page: prismicPressPage {
      uid
      slug
      data {
        title {
          text
        }
        child_of {
          document {
            slug
            data {
              title {
                text
              }
            }
          }
        }
        footer_theme
        body {
          ... on PrismicPressPageBodyHero {
            id
            slice_type
            primary {
              image {
                url
                dimensions {
                  width
                  height
                }
              }
              category {
                text
              }
              heading {
                text
              }
              show_side_panel
              enable_footer
              enable_floating_side_panel
              show_footer_item_borders
            }
          }
          ... on PrismicPressPageBodyFullWidthCalloutList {
            id
            slice_type
            primary {
              theme
            }
            items {
              callout {
                document {
                  data {
                    link {
                      raw {
                        link_type
                        slug
                        uid
                        id
                      }
                    }
                    image {
                      url
                      dimensions {
                        width
                        height
                      }
                    }
                    category_heading {
                      text
                    }
                    heading {
                      text
                    }
                    label {
                      text
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPressPageBodyFeatured {
            id
            slice_type
            primary {
              heading {
                text
              }
            }
            items {
              stories {
                document {
                  id
                  data {
                    heading {
                      text
                    }
                    url
                    publication
                    publication_logo {
                      url
                      dimensions {
                        width
                        height
                      }
                    }
                    feature_image {
                      url
                      dimensions {
                        width
                        height
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    listings: allPrismicPressListing(limit: 100) {
      edges {
        node {
          id
          data {
            heading {
              text
            }
            url
            date
            publication
          }
        }
      }
    }
  }
`;

export default PressPage;
